<template>
    <div
        class="text-input"
        :class="{ invalid: !!errorMessage, valid: meta.valid }"
    >
        <input
            v-bind="$attrs"
            :name="name"
            :type="type"
            :value="inputValue"
            :placeholder="placeholder"
            class="text-input__input"
            @input="handleInput"
            @change="handleChange"
            @blur="handleBlur"
        />
        <div
            v-show="errorMessage"
            class="text-input__validation-message validation-message"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
    import eventBus from '@tipalti/event-bus';
import { useField } from 'vee-validate';
import { defineComponent, onMounted, toRef } from 'vue';
import configService from '../services/configService';
import { DEFAULT_LOCALE, tipaltiI18n } from '../setup/i18n';
import { updateVeeValidateLocalization } from '../setup/validation';

    export default defineComponent({
        inheritAttrs: false,
        props: {
            type: {
                type: String,
                default: () => '',
            },
            value: {
                type: String,
                default: () => '',
            },
            name: {
                type: String,
                required: true,
            },
            placeholder: {
                type: String,
                default: () => '',
            },
            rules: {
                type: [String, Object, Array],
                default: undefined,
            },
        },
        emits: ['input', 'change', 'blur'],
        setup(props, context) {
            // use `toRef` to create reactive references to `name` prop which is passed to `useField`
            // this is important because vee-validate needs to know if the field name changes
            // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
            const name = toRef(props, 'name');

            // we don't provide any rules here because we are using form-level validation
            // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
                validate,
            } = useField<string>(name, props.rules as any, {
                initialValue: props.value,
                type: props.type,
            });

            onMounted(async () => {
                const config = await configService.getConfig();
                const localeEnabled = config?.localeEnabled;

                // Call the updateVeeValidateLocale at least once even if localeEnabled is false
                if (localeEnabled) {
                    const currentLocale = await tipaltiI18n.getCurrentLocale();
                    await updateVeeValidateLocale(currentLocale || DEFAULT_LOCALE); 
                } else {
                    await updateVeeValidateLocale(DEFAULT_LOCALE); 
                }
            });

            const updateVeeValidateLocale = async (locale: string) => {
                await updateVeeValidateLocalization(locale);

                if (errorMessage.value) {
                    await validate();
                }
            };

            eventBus.attachBusListener(eventBus.tipaltiEvents.LOCALE_CHANGED, (evt: any) => updateVeeValidateLocale(evt.detail.locale));
    
            return {
                handleInput: (event: Event, shouldValidate?: boolean | undefined) => {
                    handleChange(event, shouldValidate);
                    context.emit('input', (<HTMLInputElement>event.target).value);
                },
                handleChange: (event: Event, shouldValidate?: boolean | undefined) => {
                    handleChange(event, shouldValidate);
                    context.emit('change', (<HTMLInputElement>event.target).value);
                },
                handleBlur: (event: Event) => {
                    handleBlur(event);
                    context.emit('blur');
                },
                errorMessage,
                inputValue,
                meta,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .text-input {
        width: 100%;
        margin-bottom: 0.75rem;
        font-size: 1.125rem;
        font-weight: 400;

        &.invalid {
            margin-bottom: 0.25rem;
        }

        &__input {
            width: 100%;
            height: 3.5rem;
            padding-left: 1rem;
            font-size: inherit;
            font-weight: 400;
            color: $daintree;
            border: solid 1px $silver;
            border-radius: 0.125rem;

            &::placeholder {
                color: $nevada;
            }

            &:focus {
                border: 1px solid $portage !important;
                outline: none !important;
            }

            &:focus::placeholder {
                color: $transparent;
            }

            .invalid & {
                border: 1px solid $coral-red;
            }
        }

        &__validation-message {
            .invalid & {
                padding-left: 0.1875rem;
                margin-top: 0.1875rem;
                font-size: 0.75rem;
                color: $coral-red;
                text-align: left;
            }
        }
    }
</style>
