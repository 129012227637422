<template>
    <button
        class="tip-button" :class="buttonClass"
        :disabled="isDisabled"
    >
        <span>
            <FontAwesomeIcon
                v-if="loading"
                class="tip-button__icon"
                :icon="['fad', 'spinner-third']"
                pulse
            ></FontAwesomeIcon>
            <slot>{{ textContent }}</slot>
        </span>
    </button>
</template>
<script lang="ts">
    import { i18next } from '../setup/i18n';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'TipButton',
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            defaultText: {
                type: String,
                default: () => i18next.t('common.submit'),
            },
            submittingText: {
                type: String,
                default: () => '',
            },
            variant: {
                type: String,
                default: 'primary',
            },
        },
        computed: {
            textContent() {
                return this.loading ? this.submittingText : this.defaultText;
            },
            buttonClass() {
                return `tip-button--${this.variant}`;
            },
            isDisabled() {
                return this.disabled || this.loading;
            },
        }
    });
</script>
<style lang="scss" scoped>
    .tip-button {
        width: 100%;
        height: 3.5rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: var(--color-daintree);
        text-align: center;
        cursor: pointer;
        background: var(--color-brand-yellow);
        border-style: none;
        border-radius: 0.125rem;

        &:disabled {
            color: var(--color-white);
            cursor: not-allowed;
            background-color: var(--color-silver);
        }

        &__icon {
            margin-right: 0.25rem;
        }

        &--primary {
            height: 2rem;
            border-radius: 3px;
            
            @include background-color(var(--color-brand-yellow), var(--color-grandis), var(--color-selective-yellow));
            @include border-color(var(--color-brand-yellow), var(--color-grandis), var(--color-selective-yellow));
            color: var(--color-daintree);

            &:disabled,
            &.tip-button--disabled {
                @include background-color(var(--color-silver), var(--color-silver), var(--color-silver));
                @include border-color(var(--color-silver), var(--color-silver), var(--color-silver));

                color: var(--color-white);
            }
        }

        &--link {
            height: 2rem;
            border-radius: 3px;
            
            background-color: $transparent;
            border-color: $transparent;
            color: var(--color-cornflower-blue);
            &:hover {
                background-color: var(--color-wild-sand);
                &:active {
                    background-color: var(--color-galaxian-bg);
                }
            }
        }

        &--secondary {
            height: 2rem;
            border: 1px solid;
            border-radius: 3px;
            align-items: center;
            
            @include background-color(var(--color-white), var(--color-white), var(--color-galaxian-bg));
            @include border-color(var(--color-outer-space), var(--color-gray-suit), var(--color-cornflower-blue));
            color: var(--color-daintree);

            &:disabled,
            &.tip-button--disabled {
                @include background-color(var(--color-white), var(--color-white), var(--color-white));
                @include border-color(var(--color-silver), var(--color-silver), var(--color-silver));

                color: var(--color-silver);
            }
        }

        &--actionSecondary {
            height: 2.5rem;
            min-height: 2.5rem;
            border: 1px solid;
            border-radius: 3px;
            align-items: center;
            
            @include background-color(var(--color-white), var(--color-white), var(--color-galaxian-bg));
            @include border-color(var(--color-portage), var(--color-outer-space), var(--color-cornflower-blue));
            color: var(--color-daintree);

            &:disabled {
                @include background-color(var(--color-alabaster), var(--color-alabaster), var(--color-alabaster));
                @include border-color(var(--color-mercury-v2), var(--color-mercury-v2), var(--color-mercury-v2));

                color: var(--color-silver);
            }
        }
    }
</style>
