import find from 'lodash/find';
import remove from 'lodash/remove';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

function toSelectList(list: any, removeDefaultItemVal: string) {
    //If list is not an object or list is an array with non object items convert it to be an object
    if (isArray(list) && !isObject(list[0])) {
        const listObject: any = {};
        list.forEach(function (item) {
            listObject[item] = item;
        });
        list = listObject;
    }
    const arr: any = [];

    forEach(keys(list), function (key) {
        if (key !== removeDefaultItemVal) {
            arr.push({ id: key, name: list[key] });
        }
    });

    return arr;
}

function initDialCodesDescription(countryDialCodesDescription: any) {
    const countrySelectList = toSelectList(countryDialCodesDescription, '--');
    moveUsToBeFirst();

    return countrySelectList;

    function moveUsToBeFirst() {
        const usItem = find(countrySelectList, (country) => country.id === 'US');
        if (usItem !== undefined) {
            remove(countrySelectList, (item) => item === usItem);
            countrySelectList.unshift(usItem);
        }
    }
}

export interface CountriesModel {
    countryDialCodesDescription: any;
    countryDialCodes: any;
}

function createNewUserCountriesModel(countriesModel: CountriesModel) {
    const model: {
        countryDialCodesDescription: any[];
        countryDialCodes: any[];
    } = {
        countryDialCodesDescription: [],
        countryDialCodes: [],
    };
    model.countryDialCodesDescription = initDialCodesDescription(
        countriesModel.countryDialCodesDescription
    );
    model.countryDialCodes = countriesModel.countryDialCodes;

    return model;
}

export default createNewUserCountriesModel;
