import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sso-button" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "sso-button__link",
      href: _ctx.redirectSsoUrl
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "sso-button__button",
        disabled: _ctx.disabled
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.ssoButton.text')), 1 /* TEXT */)
      ], 8 /* PROPS */, _hoisted_3)
    ], 8 /* PROPS */, _hoisted_2)
  ]))
}