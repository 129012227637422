import http from '../lib/http';
import { TwoFactorUserModelProps, createTwoFactorUserModel } from '../models/twoFactorUserModel';
import createNewUserCountriesModel from '../models/newUserCountriesModel';
import loginConsts from "../consts/login/loginConsts";

async function registerUserAsync(model: any) {
    const result = await http.postAsync(loginConsts.twoFactor.apiUrls.registerUser, model);
    return createTwoFactorUserModel(result.data as TwoFactorUserModelProps);
}

async function getCountriesAsync(model?: any) {
    const result = await http.getAsync(loginConsts.twoFactor.apiUrls.countryCodes, model);
    return createNewUserCountriesModel(result.data);
}

async function sendPinCodeAsync() {
    const result = await http.postAsync(loginConsts.twoFactor.apiUrls.sendPinCode);
    return createTwoFactorUserModel(result.data);
}

async function validatePinCodeAsync(model: any) {
    return http.postAsync(loginConsts.twoFactor.apiUrls.validatePinCode, model);
}

async function resendCodeAsync() {
    return http.postAsync(loginConsts.twoFactor.apiUrls.resendCode);
}

export default {
    registerUser: registerUserAsync,
    getCountries: getCountriesAsync,
    sendPinCode: sendPinCodeAsync,
    validatePinCode: validatePinCodeAsync,
    resendCode: resendCodeAsync,
};
