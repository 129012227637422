import api from '../api';
import { defineStore } from 'pinia';

export interface ConsentState {
    isLoading: boolean;
    showErrorConsent: boolean;
    consentErrorMessage: string | null;
    isSubscribeAllowed: boolean;
    applicationName: string | null;
    userPayers: Array<any>,
    redirectUrl: string | URL | null,
    oidcReturnUrl: string | URL | null,
    clientScopes: Array<any>,
    payerName: string | null,
}

export const useConsentStore = defineStore({
    id: 'consent',
    state: (): ConsentState => {
        return {
            isLoading: false,
            showErrorConsent: false,
            consentErrorMessage: null,
            isSubscribeAllowed: false,
            applicationName: null,
            userPayers: [],
            redirectUrl: null,
            oidcReturnUrl: null,
            clientScopes: [],
            payerName: null
        };
    },
    getters: {
        
    },
    actions: {
        async canSubscribe() {
            this.isLoading = true;
            const canSubscribeResponse = await api.canSubscribe();
            this.showErrorConsent = canSubscribeResponse.showError;
            this.consentErrorMessage = canSubscribeResponse.errorMessage;
            this.isSubscribeAllowed = canSubscribeResponse.canSubscribe;
            this.applicationName = canSubscribeResponse.applicationName;
            this.isLoading = false;
        },
        async getPayersForCurrentLoginStateUser() {
            this.isLoading = true;
            const getPayersForCurrentLoginStateUserResponse = await api.getPayersForCurrentLoginStateUser();
            this.showErrorConsent = getPayersForCurrentLoginStateUserResponse.showError;
            this.consentErrorMessage = getPayersForCurrentLoginStateUserResponse.errorMessage;

            this.oidcReturnUrl = getPayersForCurrentLoginStateUserResponse.oidcReturnUrl;
            this.userPayers = getPayersForCurrentLoginStateUserResponse.payers;
            this.isLoading = false;
        },
        async createAndSigninApplicativeUser(request: any) {
            this.isLoading = true;
            
            const createAndSigninApplicativeUserRequest = {
                selectedPayerId: request.selectedPayerId,
                selectedPayerEntityIdArray: request.selectedPayerEntityIdArray
            };

            const createAndSigninApplicativeUserResponse = await api.createAndSigninApplicativeUser(createAndSigninApplicativeUserRequest);

            this.showErrorConsent = createAndSigninApplicativeUserResponse.showError;
            this.consentErrorMessage = createAndSigninApplicativeUserResponse.errorMessage;

            this.redirectUrl = createAndSigninApplicativeUserResponse.redirectUrl;

            this.isLoading = false;
        },
        async getScopesByLoginState() {
            this.isLoading = true;
    
            const getClientScopesByLoginStateResponse = await api.getScopesByLoginState();
    
            this.showErrorConsent = getClientScopesByLoginStateResponse.showError;
            this.consentErrorMessage = getClientScopesByLoginStateResponse.errorMessage;
    
            this.oidcReturnUrl = getClientScopesByLoginStateResponse.oidcReturnUrl;
            this.clientScopes = getClientScopesByLoginStateResponse.scopes;
            this.payerName = getClientScopesByLoginStateResponse.payerName;
            this.applicationName = getClientScopesByLoginStateResponse.clientName;
    
            this.isLoading = false;
        },
        async grantConsent(request: any) {
            this.isLoading = true;
    
            const grantConsentResponse = await api.grantConsent(request);
    
            this.showErrorConsent = grantConsentResponse.showError;
            this.consentErrorMessage = grantConsentResponse.errorMessage;
    
            if (!grantConsentResponse.showError) {
                this.isLoading = false;
                return grantConsentResponse.redirectUrl;
            }
        },
    },
});
