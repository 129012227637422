import axios from 'axios';

async function postAsync(
    url: string,
    data?: Record<string, unknown>,
    params?: Record<string, string>
) {
    return axios.post(url, data, {
        params,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

async function getAsync(url: string, params?: Record<string, string>) {
    return axios.get(url, {
        params,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

const httpService = {
    postAsync,
    getAsync,
    isAxiosError: axios.isAxiosError,
};

export default httpService;
