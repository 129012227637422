import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24449ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "submit",
    class: "submit-button",
    disabled: _ctx.isSubmitting
  }, [
    _createElementVNode("span", null, [
      (_ctx.isSubmitting)
        ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            icon: ['fad', 'spinner'],
            pulse: ""
          }))
        : _createCommentVNode("v-if", true),
      _createTextVNode(" " + _toDisplayString(_ctx.textContent), 1 /* TEXT */)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}