<template>
    <div class="app-subscriber-title">
        {{ title }}
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppSubscriberTitle',
    props: {
        title: {
            type: String,
            required: true
        }
    }
});
</script>
<style lang="scss">
.app-subscriber-title {
    color: $daintree;
    display: flex;
    justify-content: center;

    margin: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
}
</style>
