export interface TwoFactorUserModelProps {
    mobileNumber: string;
    resendCodeCount: number;
    attemptsCount: number;
    lastSendTime: Date;
    isNewUser: boolean;
    isVerified: boolean;
    errorMessage: string;
    hideRememberMe: boolean;
}

export type TwoFactorUserModel = {
    mobileNumber: string;
    resendCodeCount: number;
    attemptsCount: number;
    lastSendTime: Date;
    isNewUser: boolean;
    canChange: boolean;
    errorMessage: string;
    hideRememberMe: boolean;
};

export const createTwoFactorUserModel = (model: TwoFactorUserModelProps): TwoFactorUserModel => {
    return {
        mobileNumber: model.mobileNumber,
        resendCodeCount: model.resendCodeCount,
        attemptsCount: model.attemptsCount,
        lastSendTime: model.lastSendTime,
        isNewUser: model.isNewUser,
        canChange: !model.isVerified,
        errorMessage: model.errorMessage,
        hideRememberMe: model.hideRememberMe,
    };
};

export default {
    createTwoFactorUserModel,
};
