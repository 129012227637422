import httpService from '../lib/http';
import loginConsts from '../consts/login/loginConsts';
import logger from '../lib/logger';

export default {
    async initializeLoginProcess({ oidcReturnUrl }: { oidcReturnUrl: string }) {
        const apiRequestParams = {
            oidcReturnUrl,
        };

        try {
            const initializeLoginResponse = await httpService.postAsync(
                loginConsts.apiUrls.initializeLoginProcess,
                apiRequestParams
            );
            return initializeLoginResponse.data;
        } catch (ex) {
            logger.error(ex);
            throw ex;
        }
    },
    async initializeLoginByUserFlow({
        userEmail,
        oidcReturnUrl,
    }: {
        userEmail: string;
        oidcReturnUrl: string;
    }) {
        const apiRequestParams = {
            userEmail,
            oidcReturnUrl,
        };

        try {
            const response = await httpService.postAsync(
                loginConsts.apiUrls.initializeLoginByUserFlow,
                apiRequestParams
            );
            return { ...response, isValid: !!response.data };
        } catch (ex) {
            logger.error(ex);
            throw ex;
        }
    },
    async authenticateUserPassword({
        userEmail,
        password,
        oidcReturnUrl,
    }: {
        userEmail: string;
        password: string;
        oidcReturnUrl: string;
    }) {
        const apiRequestParams = {
            userEmail,
            password,
            oidcReturnUrl,
        };

        try {
            const authenticateUserPasswordResponse = await httpService.postAsync(
                loginConsts.apiUrls.authenticateUserPassword,
                apiRequestParams
            );
            if (!authenticateUserPasswordResponse.data) {
                // return { showError: true, errorMessage: langConsts.validateUserError };
            }

            authenticateUserPasswordResponse.data.showError = false;
            return authenticateUserPasswordResponse.data;
        } catch (ex) {
            logger.error(ex);
            throw ex;
        }
    },
    async canSubscribe() {
        const url = loginConsts.apiUrls.canSubscribe;
        try {
            const response = await httpService.getAsync(url);
            if (!response.data) {
                return { showError: true, errorMessage: 'views.payersList.errors.validateUserError' };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(ex);
            return { showError: true, errorMessage: 'views.payersList.errors.validateUserError' };
        }
    },
    async getPayersForCurrentLoginStateUser() {
        const url = loginConsts.apiUrls.getPayersForCurrentLoginStateUser;

        try {
            const response = await httpService.getAsync(url);

            if (!response.data) {
                return { showError: true, errorMessage: 'views.payersList.errors.getPayersForCurrentLoginStateUserError' };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(ex);
            return { showError: true, errorMessage: 'views.payersList.errors.validateUserError' };
        }
    },
    async createAndSigninApplicativeUser({
        selectedPayerId,
        selectedPayerEntityIdArray,
    }: {
        selectedPayerId: string;
        selectedPayerEntityIdArray: string[];
    }) {
        const url = loginConsts.apiUrls.createAndSigninApplicativeUser;
        const apiRequestParams = {
            selectedPayerId,
            selectedPayerEntityIdArray,
        };

        try {
            const response = await httpService.postAsync(url, apiRequestParams);

            if (!response.data) {
                return { showError: true, errorMessage: 'views.payersList.errors.createAndSigninApplicativeUser' };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(ex);
            return { showError: true, errorMessage: 'views.payersList.errors.validateUserError' };
        }
    },
    async getScopesByLoginState() {
        const url = loginConsts.apiUrls.getScopesByLoginState;
        try {
            const response = await httpService.getAsync(url);

            if (!response.data) {
                return { showError: true, errorMessage: 'views.consentScopes.errors.getClientScopesByLoginStateError' };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(ex);
            return { showError: true, errorMessage: 'views.consentScopes.errors.getClientScopesByLoginStateError' };
        }
    },
    async grantConsent({
        oidcReturnUrl,
        consentedScopes,
    }: {
        oidcReturnUrl: string;
        consentedScopes: string[];
    }): Promise<any> {
        const url = loginConsts.apiUrls.grantConsent;
        const apiRequestParams = {
            oidcReturnUrl,
            consentedScopes,
        };

        try {
            const response = await httpService.postAsync(url, apiRequestParams);

            if (!response.data) {
                return { showError: true, errorMessage: 'views.consentScopes.errors.grantConsentError' };
            }

            return { redirectUrl: response.data, showError: false };
        } catch (ex) {
            logger.error(ex);
            return { showError: true, errorMessage: 'views.consentScopes.errors.grantConsentError' };
        }
    },
    async getAuthenticatorSetupInfoAsync() {
        const url = loginConsts.twoFactor.apiUrls.getSetupInfo;
        try {
            const response = await httpService.getAsync(url);
            return response.data;
        } catch (ex) {
            // notificationService.error(loginConsts.twoFactor.getAuthenticatorSetupInfoError);
            return {
                isExistingUser: false,
                qrCodeImage: '',
                manualEntryKey: '',
                isError: true,
            };
        }
    },
    async validatePinCodeAsync({ code }: { code: string }) {
        const url = loginConsts.twoFactor.apiUrls.validateTwoFactorCode;
        const params = { code };
        try {
            const response = await httpService.postAsync(url, params);
            return response.data;
        } catch (ex) {
            logger.error(ex);
            return { validationStatus: loginConsts.twoFactor.pinCodeValidationStatus.generalError };
        }
    },
};
