<template>
    <button
        type="submit"
        class="submit-button"
        :disabled="isSubmitting"
    >
        <span>
            <FontAwesomeIcon
                v-if="isSubmitting"
                :icon="['fad', 'spinner']"
                pulse
            ></FontAwesomeIcon>
            {{ textContent }}
        </span>
    </button>
</template>
<script lang="ts">
    import { i18next } from '../setup/i18n';
    import { defineComponent, computed } from 'vue';

    export default defineComponent({
        name: 'SubmitButton',
        props: {
            isSubmitting: {
                type: Boolean,
                default: false,
            },
            defaultText: {
                type: String,
                default: () => i18next.t('common.submit'),
            },
            submittingText: {
                type: String,
                default: () => '',
            },
        },
        setup(props) {
            return {
                textContent: computed(() =>
                    props.isSubmitting ? props.submittingText : props.defaultText
                ),
            };
        },
    });
</script>
<style lang="scss" scoped>
    .submit-button {
        width: 100%;
        height: 3.5rem;
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-weight: 400;
        color: $daintree;
        text-align: center;
        cursor: pointer;
        background: $selective-yellow;
        border-style: none;
        border-radius: 0.125rem;

        &:disabled {
            color: $white;
            cursor: not-allowed;
            background-color: $silver;
        }
    }
</style>
