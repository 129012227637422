import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8093e5a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["tip-button", _ctx.buttonClass]),
    disabled: _ctx.isDisabled
  }, [
    _createElementVNode("span", null, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            class: "tip-button__icon",
            icon: ['fad', 'spinner-third'],
            pulse: ""
          }))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.textContent), 1 /* TEXT */)
      ], true)
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}