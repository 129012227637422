import isNil from 'lodash/isNil';
import { defineStore } from 'pinia';
import api from "../api";

export const SET_LOADING = 'SET_LOADING';
export const SET_OIDC_RETURN_URL = 'SET_OIDC_RETURN_URL';
export const SET_LOGIN_PROCESS_INITIAZLIZED = 'SET_LOGIN_PROCESS_INITIAZLIZED';
export const SET_SSO_LOGIN_URL = 'SET_SSO_LOGIN_URL';
export const SET_IS_APP_LOGIN_FLOW_SSO_ONLY = 'SET_IS_APP_LOGIN_FLOW_SSO_ONLY';
export const SET_IDENTITY_PROVIDER_TYPE = 'SET_IDENTITY_PROVIDER_TYPE';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_IS_USER_LOGIN_FLOW_SSO = 'SET_IS_USER_LOGIN_FLOW_SSO';
export const SET_IS_USER_LOGIN_FLOW_PASSWORD = 'SET_IS_USER_LOGIN_FLOW_PASSWORD';
export const SET_IS_USER_PASSWORD_VALID = 'SET_IS_USER_PASSWORD_VALID';
export const SET_IS_USER_EMAIL_VALID = 'SET_IS_USER_EMAIL_VALID';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_USER_PAYERS = 'SET_USER_PAYERS';
export const SET_CAN_SUBSCRIBE = 'SET_CAN_SUBSCRIBE';
export const SET_APPLICATION_NAME = 'SET_APPLICATION_NAME';
export const SET_SHOW_ERROR_LOGIN = 'SET_SHOW_ERROR_LOGIN';
export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';

export interface LoginState {
    isLoading: boolean;
    loginProcessInitialized: boolean;
    oidcReturnUrl: string | null,
    isAppLoginFlowSsoOnly: boolean;
    isUserLoginFlowSso: boolean;
    isUserLoginFlowPassword: boolean;
    ssoLoginUrl: string | null;
    identityProviderType: string | null;
    userEmail: string | null;
    isUserPasswordValid: boolean;
    isUserEmailValid: boolean;
    redirectUrl: string | URL | null,
    isSubscribeAllowed: boolean;
    applicationName: string | null;
    showErrorLogin: boolean;
    loginErrorMessage: string | null;
    loginApplicationName: string | null,
}

export const useLoginStore = defineStore({
    id: 'login',
    state: (): LoginState => ({
        isLoading: false,
        loginProcessInitialized: false,
        oidcReturnUrl: null,
        isAppLoginFlowSsoOnly: false,
        isUserLoginFlowSso: false,
        isUserLoginFlowPassword: false,
        ssoLoginUrl: null,
        identityProviderType: null,
        userEmail: null,
        isUserPasswordValid: false,
        isUserEmailValid: false,
        redirectUrl: null,
        isSubscribeAllowed: false,
        applicationName: null,
        showErrorLogin: false,
        loginErrorMessage: '',
        loginApplicationName: null,
    }),
    getters: {

    },
    actions: {
        async initializeLoginProcess(request: any) {
            this.setLoading(true);
            this.setOidcReturnURL(request.oidcReturnUrl);

            try {
                const response = await api.initializeLoginProcess(request);
                const {
                    ssoRedirectUrl,
                    isLoginFlowSsoOnly,
                    isLoginFlowPassword,
                    identityProviderType,
                    loginApplicationName,
                    userEmail
                } = response;

                this.setLoginProcessInitialized(isNil(response));
                this.setSsoLoginURL(ssoRedirectUrl);
                this.setIsAppLoginFlowSsoOnly(isLoginFlowSsoOnly);
                this.setIdentityProviderType(identityProviderType);
                this.setIsLoginFlowPassword(isLoginFlowPassword);
                this.setLoginApplicationName(loginApplicationName);
                this.setUserEmail(userEmail);
            } catch (ex) {
                throw ex;
            } finally {
                this.setLoading(false);
            }
        },
        async initializeLoginByUserFlow(request: any) {
            this.setLoading(true);
            this.setUserEmail(request.userEmail);

            try {
                const response = await api.initializeLoginByUserFlow(request);
                const { data, isValid } = response;

                this.setIsUserEmailValid(isValid);
                if (isValid) {
                    this.setIsUserLoginFlowSso(data.isLoginFlowSso);
                    this.setIsLoginFlowPassword(data.isLoginFlowPassword);
                    this.setIdentityProviderType(data.identityProviderType);
                    this.setSsoLoginURL(data.ssoRedirectUrl);
                }

                return response;
            } catch (ex) {
                this.setIsUserEmailValid(false);
                throw ex;
            } finally {
                this.setLoading(false);
            }
        },
        setOidcReturnURL(oidcReturnUrl: string) :void {
            this.oidcReturnUrl = oidcReturnUrl;
        },
        setSsoLoginURL(ssoRedirectUrl: string) :void {
            this.ssoLoginUrl = ssoRedirectUrl;
        },
        setIsAppLoginFlowSsoOnly(isLoginFlowSsoOnly: boolean) :void {
            this.isAppLoginFlowSsoOnly = isLoginFlowSsoOnly;
        },
        setIsLoginFlowPassword(isLoginFlowPassword: boolean) :void {
            this.isUserLoginFlowPassword = isLoginFlowPassword;
        },
        setIsUserLoginFlowSso(isUserLoginFlowSso: boolean) :void {
            this.isUserLoginFlowSso = isUserLoginFlowSso;
        },
        setIdentityProviderType(identityProviderType: any) :void {
            this.identityProviderType = identityProviderType;
        },
        setLoading(isLoading: boolean) :void {
            this.isLoading = isLoading;
        },
        setLoginProcessInitialized(isInitialized: boolean) :void {
            this.loginProcessInitialized = isInitialized;
        },
        setLoginApplicationName(applicationName: string) :void {
            this.loginApplicationName = applicationName;
        },
        setUserEmail(email: string) :void {
            this.userEmail = email;
        },
        setIsUserEmailValid(isValid: boolean) :void {
            this.isUserEmailValid = isValid;
        }
    }
});