import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a5bb81d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "type", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-input", { invalid: !!_ctx.errorMessage, valid: _ctx.meta.valid }])
  }, [
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      name: _ctx.name,
      type: _ctx.type,
      value: _ctx.inputValue,
      placeholder: _ctx.placeholder,
      class: "text-input__input",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
    }), null, 16 /* FULL_PROPS */, _hoisted_1),
    _withDirectives(_createElementVNode("div", { class: "text-input__validation-message validation-message" }, _toDisplayString(_ctx.errorMessage), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 2 /* CLASS */))
}