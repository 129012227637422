<template>
    <div class="sso-button">
        <a
            class="sso-button__link"
            :href="redirectSsoUrl"
        >
            <button
                type="button"
                class="sso-button__button"
                :disabled="disabled"
            >
                <span>{{ $t('components.ssoButton.text') }}</span>
            </button>
        </a>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'SSOButton',
        props: {
            redirectSsoUrl: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>

<style lang="scss">
    .sso-button {
        &__button {
            width: 100%;
            height: 3.5rem;
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            color: $nevada;
            text-align: center;
            cursor: pointer;
            background: $white;
            border-style: none;
            border-radius: 0.125rem;
            box-shadow: 0 0 0.375rem rgba($black, 0.3);

            &:disabled {
                color: $white;
                cursor: not-allowed;
                background-color: $silver;
            }
        }
    }
</style>
