import router from '../lib/router';
import RouteNames from '../lib/route-names';
import { NavigationFailure } from 'vue-router';
import loginConsts from '../consts/login/loginConsts';

export interface StateService {
    stateServiceType: string;
}

export function goToLogin(query?: Record<string, string>) {
    return router.push({ name: 'username', query });
}

export default {
    goToForgotPassword(query?: Record<string, string>) {
        return router.push({ name: RouteNames.forgotPassword, query });
    },
    goToLoginWithSource(source: string) {
        return router.push({ name: RouteNames.username, query: { source } });
    },
    goToLoginWithApproverToken(billApproverToken: string) {
        return router.push({
            name: RouteNames.username,
            query: { billApproverToken },
        });
    },
    goToLogin,
    goToLoginUserName(query?: Record<string, string>) {
        return router.replace({ name: RouteNames.username, query });
    },
    goToLoginPassword(
        username: string,
        redirectSsoUrl: string,
        query: Record<string, string> = {}
    ) {
        return router.replace({
            name: RouteNames.password,
            query: { ...query, username, redirectSsoUrl },
        });
    },
    goToForgotPasswordSent(email: string): Promise<NavigationFailure | void | undefined> {
        return router.push({ name: RouteNames.forgotPasswordSent, query: { email } });
    },
    goToExpired(username: string): Promise<NavigationFailure | void | undefined> {
        return router.push({ name: RouteNames.passwordExpired, query: { username } });
    },
    goToTwoFactor(
        subState: string,
        query?: Record<string, string>
    ): Promise<NavigationFailure | void | undefined> {
        return router.push({
            name: `two-factor.${subState}`,
            query,
        });
    },
    goToConsentFlow(): Promise<NavigationFailure | void | undefined> {
        return router.replace(loginConsts.consentUrls.consentStartUrl);
    },
    redirectUrl(redirectUrl: string, query?: Record<string, string>): boolean {
        let url = redirectUrl;
        if (query) {
            const tempUrl = new URL(url);
            const tempParams = Object.fromEntries(tempUrl.searchParams.entries())
            tempUrl.search = new URLSearchParams({...tempParams, ...query}).toString();
            url = tempUrl.toString();
        }

        window.location.href = url;
        return false;
    },
};
